import ResizeDisk from '@/layouts/Stack/components/ResizeDisk';
import showErrorModal from '@/mixins/showErrorModal';
import Vue from 'vue';
import modals from '@/mixins/modals';
import BaseLoader from '@/components/BaseLoader/BaseLoader.vue';

export default {
  mixins: [showErrorModal, modals],
  computed: {
    quota() {
      return this.$store.getters['moduleStack/aviableSpaseVolume'];
    },
    projectId() {
      return this.$store.getters['moduleStack/projectId'];
    },
    id() {
      return this.tariff.id;
    },
    bootable() {
      return this.tariff.bootable === 'true';
    },
    type() {
      return this.tariff.volume_type;
    },
  },
  methods: {
    resizeDisk: function (size, project, disk) {
      const that = this;
      let self;
      return new Promise(() => {
        const selfName = 'ResizeDisk';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: ResizeDisk,
          closeOnBackdrop: false,
          props: {
            bootable: this.bootable,
            quota: this.quota,
            // size: size + 1,
            size: size,
            type: this.type,
          },
          text: this.$t('sure.text'),
          on: {
            size: data => {
              size = data;
            },
            ready: () => {
              Vue.set(self.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(self.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (self = inst),
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm') },
              on: {
                click: async () => {
                  const isSure = size > 2048 ? await this.askSure('ewewewewewewe') : true;
                  if (isSure) {
                    this.sendResizeDisk(project, disk, size).then(data => {
                      if (data.status === 202) {
                        this.$modals.close({ name: selfName });
                        const payload = { project: this.projectId, disk: this.id };
                        this.$store
                          .dispatch('moduleStack/fetchQuotasVolume')
                          .catch(e => console.log(e));
                        this.$store
                          .dispatch('moduleStack/checkDiskStatus', payload)
                          .then(async data => {
                            const res = await data.status;
                            if (res !== 'in-use') {
                              return this.updateDiskStatus(_, {
                                project: this.projectId,
                                disk: this.id,
                                type: 'volumes',
                                silent: true,
                              });
                            }
                          })
                          .catch(e => this.showError(e));
                        this.showResModal(
                          'Размер диска успешно увеличен, дождитесь изменения статуса.'
                        );
                      }
                    });
                  } else {
                    that.$modals.close();
                  }
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    async updateDiskStatus(callback, payload) {
      this.timerId = setTimeout(() => {
        this.$store
          .dispatch('moduleStack/checkDiskStatus', {
            project: this.projectId,
            disk: this.id,
            type: 'volumes',
            silent: true,
          })
          .then(async data => {
            if (['extending'].includes(data.status)) {
              this.res = data.status;
              await this.updateDiskStatus(callback, payload);
            } else {
              // console.log(data);
              this.timerCnt = 0;
              this.isProcessing = false;
              clearTimeout(this.timerId);
              this.res = data.status;
              return 'success';
            }
          });
      }, 1000 * this.timerCnt);
    },
    sendResizeDisk(project, disk, size) {
      const params = {
        project: project,
        disk: disk,
        size: size,
      };
      return this.$store.dispatch('moduleStack/resizeDisk', params).catch(e => {
        console.log(e);
        this.showError(e);
      });
    },
    askSure(msg) {
      return new Promise(resolve => {
        this.$modals.open({
          name: 'AskSure',
          text: this.$t('sure.text'),
          onOpen: inst => (this.sureModal = inst),
          onClose: () => (this.sureModal = null),
          onDismiss: () => resolve(false),
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm'), color: 'error' },
              on: {
                click: () => resolve(true),
              },
            },
            cancel: {
              on: {
                click: () => resolve(false),
              },
            },
          },
        });
      });
    },
  },
};
